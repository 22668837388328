import React from 'react'
import styled from 'styled-components'
import { ReUsePtag } from './ReUsePtag'
import { ReUseHTwotag } from './ReUseHTwoTag'
import RaindropasaDifferentiatorRestData from '../Data/RaindropasaDifferentiatorRestData'

const AppRaindropasaDifferentiatorRestWapper = styled.div`
  max-width: 1920px;
  margin: auto;
  .RetailIndustryContentSectionEmail {
    padding: 5px;
    @media (max-width: 1090px) {
      padding: 15px;
    }
  }
  .RetailIndustryHeadingEmail {
    max-width: 1150px;
    padding: 15px 15px 15px 45px;
    @media (max-width: 1090px) {
      padding: unset;
      max-width: unset;
    }
  }
  .RetailIndustryLeadParaStyleOne {
    max-width: 1150px;
    padding: 0px 15px 15px 45px;
    @media (max-width: 1090px) {
      padding: unset;
      max-width: unset;
    }
  }
  .RetailIndustryLeadParaStyle {
    max-width: 1150px;
    padding: 15px 15px 15px 45px;

    @media (max-width: 1090px) {
      padding: unset;
      max-width: unset;
    }
  }
  .achorPara {
    color: #34a1d5;
    font-size: 20px;
    font-weight: 700;
    padding-right: 8px;
    padding-left: 8px;
    text-decoration: none;
  }
  .achorPara:hover {
    color: #047cc2;
    text-decoration: underline;
  }
  .iconColor {
    color: #34a1d5;
    padding: 15px 15px 15px 0px;
  }
  .RetailIndustryParaSubPointStyle {
    color: #047cc2;
    max-width: 1150px;
    padding: 15px 15px 15px 45px;
    font-size: 22px;
    font-weight: bold;
    @media (max-width: 1090px) {
      padding: unset;
      max-width: unset;
    }
  }
  .ItalyPara {
    display: flex;
    color: #071741;
    max-width: 1150px;
    font-size: 1.2rem;
    line-height: 1.4;
    padding: 0px 15px 15px 45px;
    @media (max-width: 1090px) {
      padding: unset;
      max-width: unset;
      font-size: 16px;
    }
  }
  .BlogsimageOne {
    width: 90%;
    display: flex;
    padding-left: 24px;
    /* float: right; */
    @media (max-width: 1090px) {
      float: unset;
      width: 100%;
    }
  }
  .RetailLeadParaStyleOne {
    padding: 0px 15px 0px 45px;
  }
  .RetailIndustryHeadingFiveEmail {
    padding: 15px 15px 15px 45px;
  }

  .RaindropasaDifferentiator {
    padding: 15px 15px 15px 45px;
    color: #34a1d5;
    font-size: 28px;
    /* font-style: italic; */
  }

  .ParaStyleMultiple {
    padding: 0px 15px 0px 45px;
  }
  .RFPProcessLeadParaSubPointStyle {
    color: #047cc2;
    max-width: 1150px;
    padding: 15px 15px 15px 45px;
    font-size: 22px;
    font-weight: bold;
    @media (max-width: 1090px) {
      padding: unset;
      max-width: unset;
    }
  }
`

export const AppRaindropasaDifferentiatorRest = () => {
  return (
    <AppRaindropasaDifferentiatorRestWapper>
      <div>
        {RaindropasaDifferentiatorRestData.map((data, index) => {
          return (
            <div className="MainDigitizationNeedsSection" key={index}>
              <div className="RetailIndustryContentSectionEmail">
                <ReUsePtag para={data.ParaOne} paraStyle="RetailIndustryLeadParaStyleOne" />
                <ReUsePtag para={data.ParaTwo} paraStyle="ParaStyleMultiple" />
                <ReUsePtag para={data.ParaThree} paraStyle="ParaStyleMultiple" />
                <ReUsePtag para={data.ParaFour} paraStyle="ParaStyleMultiple" />
                <ReUseHTwotag Heading={data.HeadingOne} HeadingStyle="RetailIndustryHeadingFiveEmail" />
                <ReUsePtag para={data.ParaFive} paraStyle="RetailIndustryLeadParaStyleOne" />
                <ReUsePtag para={data.ParaSix} paraStyle="RetailIndustryLeadParaStyleOne" />
                <ReUseHTwotag Heading={data.HeadingTwo} HeadingStyle="RaindropasaDifferentiator" />
                <ReUsePtag para={data.ParaSeven} paraStyle="RetailIndustryLeadParaStyle" />
                <ReUseHTwotag Heading={data.HeadingThree} HeadingStyle="RetailIndustryHeadingFiveEmail" />
                <ReUsePtag para={data.ParaEight} paraStyle="RetailIndustryLeadParaStyle" />
              </div>
            </div>
          )
        })}
      </div>
    </AppRaindropasaDifferentiatorRestWapper>
  )
}
