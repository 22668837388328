import React from 'react'
import styled from 'styled-components'
import { AppSideNavEmailName } from './AppSideNavEmailName'
import { AppRaindropasaDifferentiatorRest } from './AppRaindropasaDifferentiatorRest'

const AppRaindropasaDifferentiatorWithNavWapper = styled.div`
  max-width: 1920px;
  margin: auto;
  .EnablesBusinessesSection {
    display: flex;
    justify-content: space-between;
    padding: 50px;
    @media (max-width: 1090px) {
      flex-wrap: wrap;
      padding: 15px;
    }
  }
  .EnablesBusinessesNav {
    order: 0;
    @media (max-width: 1090px) {
      order: 1;
      margin: auto;
    }
  }
  .EnablesBusinessesSectionContent {
    order: 1;
    @media (max-width: 1090px) {
      order: 0;
    }
  }
`

export const AppRaindropasaDifferentiatorWithNav = () => {
  return (
    <AppRaindropasaDifferentiatorWithNavWapper>
      <div className="EnablesBusinessesSection">
        <div className="EnablesBusinessesNav">
          <AppSideNavEmailName />
        </div>
        <div className="EnablesBusinessesSectionContent">
          <AppRaindropasaDifferentiatorRest />
        </div>
      </div>
    </AppRaindropasaDifferentiatorWithNavWapper>
  )
}
