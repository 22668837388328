import React from 'react'
import { FaArrowCircleRight } from 'react-icons/fa'

export default [
  {
    id: '1',
    icon: <FaArrowCircleRight />,
    PointPara: '⟶',
    ParaOne:
      'As the insurance sector is driven by data availability and accuracy, industry stalwarts look forward to big data and AI (Artificial Intelligence) to turn their ‘big data’ into valuable and actionable business intelligence.  When applying these exact same characteristics to the Enterprise Spend Management side, rapid access to end-to-end actionable intelligence across suppliers, contracts, spend, risk, compliance, and payables, is the heartbeat which drives value. It’s the game-changer.',
    ParaTwo: 'This is exactly where Raindrop comes in.',
    ParaThree:
      'Due to the COVID-19 pandemic crisis, individuals and businesses have been forced into changing the way risk is being viewed.  This is true across the entire insurance industry, be it life, health, and/or disability insurance policies. Add on natural disasters, and the past three years have created a more conservative insurance risk profile. But as the adage goes, “necessity is the mother of invention”, so with these challenges come new solutions for leaders to transform their businesses and drive value from the data they have while investing in the right technologies to transform their business.',
    ParaFour:
      'Visionary insurance leaders leverage digital transformation to move from a transactional approach to their external supplier spend, to strategic actions. Raindrop empowers businesses not just to live up to their existing challenges and responsibilities, but also future-proof their business with a 360-degree view of their supply base.',
    HeadingOne: 'Save critical resources with Raindrop – Time and Personnel',
    ParaFive:
      'Granted, the past three years have compelled insurance industry leaders to refocus on where value can be obtained and risk reduced to their existing businesses. Like most large organizations, your group may use legacy software solutions to manage all external supplier spend. These legacy technologies may require significant internal resources to manage these platforms.  Manual work outside of these legacy platforms, due to lack of integrations and/or best-of-breed functionality may force your team to perform time-consuming and expensive work to extract the nugget of information you’re truly in search of. Your internal stakeholders may not adopt these legacy solutions due to outdated user interfaces and/or user experiences.  This is why Raindrop is a proudly new industry disruptor, and your go-to strategic partner for turning your team from reactive to predictive and proactive.',
    ParaSix: 'The success mantra in the insurance industry is:',
    HeadingTwo: 'Accurate Data => precise risk calculations => higher profits',
    ParaSeven:
      'While the entire insurance industry has been especially impacted by the past three years, a great amount of investment in time and resources have been required to keep pace with the need to reduce costs in order to support healthy operating margins. To succeed in this new normal, insurers need an intelligent spend management solution like Raindrop to deliver end-to-end management of every dollar leaving your company.  Real-time predictive insights via Raindrop’s Machine Learning and Artificial Intelligence, ensure a compliant environment, provides internal stakeholders with self-service support and visibility of all sourcing needs, and provide transparency via line of sight into every supplier. With Raindrop, insurance companies can gain more visibility and better control through the intelligent use of their existing data. Executing data-driven decisions, and speeding up analysis, negotiations and contracts, mitigates spend leakage and digitizes your environment to provide rapid and rich insights.',
    HeadingThree: 'Raindrop is the key to Spend Intelligence',
    ParaEight:
      'Raindrop’s spend management platform is the key to thrive and succeed, providing timely and reliable information. Diverse Raindrop modules can help to understand clearly what drives operating expenses and observe various trends to build a broad and sustainable culture of spend management over time. It has become critical for insurance companies to switch to digital transformation and sustained operational excellence. Raindrop helps organizations to identify, validate and refine opportunities to empower decision-makers for future-proof strategies. Raindrop spend, contract, supplier, and payables intelligence support insurance companies’ initiatives to become intelligent enterprises by transforming the procurement process across all direct and indirect spend categories.',
  },
]
